import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from '@mui/material/Slider';
import FormControl from '@mui/material/FormControl';

function App() {
    const [words, setWords] = useState(null);
    const [wordCount, setWordCount] = useState(5);
    const [interval, setLocalInterval] = useState(null);
    const [timer, setTimer] = useState(0);
    const apiURL = 'https://words.justrandom.net/words/';

    const fetchData = async (wordCount) => {
        const response = await axios.get(apiURL + wordCount);
        console.log(response);
        const words = response.data.words;
        setWords(words);
    };

    useEffect(() => {
        clearInterval(interval);
        if (timer > 0) {
            fetchData(wordCount);
            setLocalInterval(
                setInterval(() => fetchData(wordCount), timer * 1000)
            );
        }
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line
    }, [timer, wordCount]);

    const timerMarks = [
        {
            value: 0,
            label: 'Off'
        },
        {
            value: 3,
            label: 'Fast'
        },
        {
            value: 15,
            label: 'Slow'
        }
    ];

    const wordsMarks = [
        {
            value: 3,
            label: 3
        },
        {
            value: 4,
            label: 4
        },
        {
            value: 5,
            label: 5
        },
        {
            value: 6,
            label: 6
        },
        {
            value: 7,
            label: 7
        },
        {
            value: 8,
            label: 8
        },
        {
            value: 9,
            label: 9
        }
    ];

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-header">
                            <FormControl fullWidth>
                                <h2>Number of words</h2>
                                <Slider
                                    aria-label="Word Count"
                                    value={wordCount}
                                    step={1}
                                    marks={wordsMarks}
                                    min={3}
                                    max={9}
                                    onChange={(event) => {
                                        setWordCount(event.target.value);
                                    }}
                                />
                            </FormControl>
                            <hr></hr>
                            <FormControl fullWidth>
                                <h2>Speed</h2>
                                <Slider
                                    aria-label="Timer Count"
                                    defaultValue={0}
                                    value={timer}
                                    valueLabelDisplay="off"
                                    marks={timerMarks}
                                    step={3}
                                    min={0}
                                    max={15}
                                    onChange={(event) => {
                                        setTimer(event.target.value);
                                    }}
                                />
                            </FormControl>
                        </div>
                        {words && (
                            <div className="card-body">
                                <div>
                                    {words &&
                                        words.map((word, index) => {
                                            return (
                                                <div
                                                    className="word"
                                                    key={index}
                                                >
                                                    <h2>{word}</h2>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
